import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BASE_URL } from "../../Utility/constants";
import SurveyForm from "../Survey/SurveryForm";
import {
  checkLoginStatus,
  fetchAllPagesBySurveyId,
  // fetchActiveSurveysByRespondentId,
  fetchCountryCodes,
  fetchRespondentDetailsByUsername,
  getLoginAuthenticated,
  setRespondantDetails,
  setActiveSurvey,
  setLoginTextCurrentView,
} from "../Survey/Survey.slice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import LandingPage from "./LandingPage";
import { setBuilderLogin } from "../Survey/Survey.slice";

const Home = () => {
  // const questionsInPage = useSelector(
  //   (state) => state.survey.questionsInPage.questionGroupDTOs
  // );
  const navigate = useNavigate();
  const { state } = useLocation();
  const landingStatus = useSelector((state) => state.survey.showLanding);
  const userData = useSelector((state) => state.survey.loggedInUserData);
  const [showLanding, setShowLanding] = useState(landingStatus);
  const [activeFilings, setActiveFilings] = useState(null);
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true);
  const [showSurveyList, setShowSurveyList] = useState(false);
  const [showNoSurvey, setShowNoSurvey] = useState(false);
  const userID = useSelector((state) => state.survey.respondentDetails?.id);
  const isbuilderLogin = useSelector((state) => state?.survey?.isbuilderLogin);
  const loginTextCurrentView = useSelector(
    (state) => state?.survey?.loginTextCurrentView
  );

  // useEffect(() => {
  //   if (questionsInPage?.length > 0) {
  //     setShowLanding(false);
  //   }
  // }, [questionsInPage]);

  useEffect(() => {
    // dispatch(fetchRespondentDetailsByUsername(6731275));
    dispatch(checkLoginStatus()).then((res) => {
      console.log("the response of login api: ", res);
      if (res?.payload) {
        if (state?.fromBuilder === true || isbuilderLogin) {
          dispatch(setBuilderLogin(true));
          setShowLoading(false);
          setShowNoSurvey(false);
          navigate("/");
        } else if (!isbuilderLogin) {
          dispatch(getLoginAuthenticated()).then((response) => {
            const activeSurvey =
              response?.payload?.survey !== null
                ? response?.payload?.survey
                : response?.payload?.activeSurveys?.[0];
            if (typeof activeSurvey === "object" && "id" in activeSurvey) {
              dispatch(setActiveSurvey([activeSurvey]));
              dispatch(fetchAllPagesBySurveyId(activeSurvey?.id));
            }

            if (response?.payload?.surveyRespondents) {
              setNotificationFilingState(response?.payload?.surveyRespondents);
            }
            if (response?.payload?.surveyFiling) {
              dispatch(
                setRespondantDetails(response?.payload?.surveyRespondent)
              );
              setShowLoading(false);
              setShowNoSurvey(false);
              navigate("/");
            } else if (
              !response?.payload?.surveyFiling &&
              response?.payload?.activeSurveyFilings?.length
            ) {
              // show list
              setActiveFilings(response?.payload?.selectableSurveyFilings);
              if (state?.showFilingList !== false) {
                setShowSurveyList(true);
              }
              setShowLoading(false);
              setShowNoSurvey(false);
            } else if (
              !response?.payload?.surveyFiling &&
              !response?.payload?.activeSurveyFilings?.length
            ) {
              // display message
              setShowLoading(false);
              setShowNoSurvey(true);
            }
          });
        }
        // navigate('/');
      } else {
        // handle unauthorised case.
        window.location.href = `${BASE_URL}/taker/ssoAnnualTakerLogin`;
        // window.location.href =  'http://localhost:8080/api/v1/taker/ssoAnnualTakerLogin';
      }
    });
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(fetchRespondentDetailsByUsername(6731275));
  // }, [dispatch, ]);

  useEffect(() => {
    setShowLanding(landingStatus);
  }, [landingStatus]);

  useEffect(() => {
    dispatch(fetchCountryCodes());
  }, [dispatch]);

  // useEffect(() => {
  //   if (userID) {
  //     dispatch(fetchActiveSurveysByRespondentId(userID)).then((data) => {
  //       setShowLoading(false);
  //     });
  //   }
  // }, [dispatch, userID]);

  const setNotificationFilingState = async (surveyRespondents) => {
    if (loginTextCurrentView.length === 0) {
      let payload = [];
      surveyRespondents?.forEach((respondent) => {
        const arr = {
          id: respondent.id,
          isLoginText: false,
        };
        payload.push(arr);
      });
      dispatch(setLoginTextCurrentView(payload));
    }
  };
  const handleSectionClick = async (filing) => {
    await dispatch(setRespondantDetails(filing?.mainSurveyRespondent));
    setShowSurveyList(false);
  };

  const getOrganizationName = (org) => {
    if (org?.attribute4 && org?.attribute4 !== ".") {
      return `${org?.name} (${org?.customId}) (System ID: ${org?.attribute4})`;
    } else {
      return `${org?.name} (${org?.customId})`;
    }
  };

  return showLoading ? (
    <>
      <Box
        display="flex"
        color="primary"
        sx={{ height: "100vh", width: "100%", fontSize: "24px" }}
        alignItems="center"
        justifyContent="center"
      >
        One moment please...
      </Box>
    </>
  ) : (
    <>
      {showNoSurvey ? (
        <>
          <Box
            display="flex"
            color="primary"
            sx={{ height: "100vh", width: "100%", fontSize: "24px" }}
            alignItems="center"
            justifyContent="center"
          >
            <div>
              The Annual Survey site www.ahasurvey.org is currently closed. Thank you
              for completing the 2023 AHA survey.  We will return in January for the 2024 Annual survey.
              If you have any questions please email
              <span>
               <a href="mailto:surveysupport@aha.org">surveysupport@aha.org</a>
              </span> or you may also call our hotline 1 800 530-9092. Thank you again for your support.
            </div>
          </Box>
        </>
      ) : (
        <>
          {showSurveyList ? (
            <>
              <Typography display="inline" sx={{ color: "maroon" }}>
                Please check the list of hospital(s) before you click on the
                name. If this is not accurate please contact us at survey
                support at{" "}
                <a href="mailto:surveysupport@aha.org">surveysupport@aha.org</a>{" "}
                so that we can update your list. Opening a facility that is not
                meant for you is a violation of your agreement.
              </Typography>
              <TableContainer
                component={Card}
                sx={{
                  marginBottom: "40px",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 6px 0px rgba(62, 73, 84, 0.04)",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: "15px 30px " }}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Name
                        </Typography>
                      </TableCell>
                      <TableCell style={{ padding: "15px 30px " }}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell style={{ padding: "15px 30px " }}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Last modified
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeFilings?.map((filing, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell
                            style={{ width: "50%", padding: "15px 30px " }}
                          >
                            <Link
                              to="#"
                              onClick={() => handleSectionClick(filing)}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              onMouseOver={(e) =>
                                (e.target.style.color = "#003087")
                              }
                              onMouseOut={(e) =>
                                (e.target.style.color = "inherit")
                              }
                            >
                              <Typography style={{ fontWeight: "bold" }}>
                                {getOrganizationName(
                                  filing?.surveyOrganization
                                )}
                              </Typography>
                            </Link>
                          </TableCell>
                          <TableCell style={{ padding: "15px 30px " }}>
                            {filing?.surveyFilingStatus}
                          </TableCell>
                          <TableCell style={{ padding: "15px 30px " }}>
                            {dayjs
                              .utc(filing?.surveyFiling?.dateModified)
                              .local()
                              .format("MM-DD-YYYY hh:mm A")}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>{showLanding ? <LandingPage /> : <SurveyForm />}</>
          )}
        </>
      )}
    </>
  );
};

export default Home;
